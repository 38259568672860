<template>
  <v-container v-if="$can([titlePerms + '_list'])" fluid>
    <v-row justify="center">
      <v-col cols="12" md="6" v-for="informe in informes" :key="informe.id">
        <v-card
          class="pa-5"
          outlined
          @click="navegarAInforme(informe.ruta)"
          :disabled="informe.isDisabled"
        >
          <v-card-text class="d-flex justify-center">
            <v-icon large>{{ informe.icono }}</v-icon
            ><v-card-title>{{ informe.titulo }}</v-card-title>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" @click="$router.push('/')">Atrás</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SeleccionInformes",
  data: () => ({
    titlePerms: "gestion_stock_estampillas",
    keyInformes: 0,
    informes: [],
    /*informes: [
      {
        id: 1,
        isDisabled: false,
        titulo: "Informe de Stock",
        icono: "mdi-file-chart-outline",
        ruta: "/stock-estampillas/informe_stock_estampillas",
      },
      {
        id: 2,
        isDisabled: true,
        titulo: "Ingreso de Stock",
        icono: "mdi-upload-box-outline",
        ruta: "",
      },
      {
        id: 3,
        isDisabled: true,
        titulo: "Inhabilitación de Stock",
        icono: "mdi-close-box-outline",
        ruta: "",
      },
      {
        id: 4,
        isDisabled: true,
        titulo: "Historial de Estampillas",
        icono: "mdi-history",
        ruta: "",
      },
    ],*/
  }),
  created() {
    this.setItems();
  },
  methods: {
    navegarAInforme(ruta) {
      this.$router.push(ruta);
    },
    setItems() {
      //evaluo los accesos en base a los permisos
      if (this.$can(["gestion_stock_estampillas_informe"]) === true) {
        this.informes.push({
          id: 1,
          isDisabled: false,
          titulo: "Informe de Stock",
          icono: "mdi-file-chart-outline",
          ruta: "/stock-estampillas/informe_stock_estampillas",
        });
      }
      if (this.$can(["gestion_stock_estampillas_ingreso"]) === true) {
        this.informes.push({
          id: 2,
          isDisabled: true,
          titulo: "Ingreso de Stock",
          icono: "mdi-upload-box-outline",
          ruta: "",
        });
      }
      if (this.$can(["elementos_aduana_add_to_cart"]) === true) {
        this.informes.push({
          id: 3,
          isDisabled: true,
          titulo: "Inhabilitación de Stock",
          icono: "mdi-close-box-outline",
          ruta: "",
        });
      }
      if (this.$can(["elementos_aduana_sell"]) === true) {
        this.informes.push({
          id: 4,
          isDisabled: true,
          titulo: "Historial de Estampillas",
          icono: "mdi-history",
          ruta: "",
        });
      }
    },
  },
};
</script>
